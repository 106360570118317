import { SipgateDomain, SipgateProduct } from '../redux/slices/signup';
import { SupportedLocales } from '../types';

export const getCountry = (location: Location): string => {
	if (location.hostname.includes('sipgate.co.uk')) return 'GB';
	if (location.hostname.includes('clinq.com')) return 'US';
	return 'DE';
};

export const getDomain = (location: Location): SipgateDomain => {
	if (location.hostname.includes('sipgate.co.uk')) return 'sipgate.co.uk';
	if (location.hostname.includes('clinq.com')) return 'clinq.com';
	return 'sipgate.de';
};

export const getLanguage = (location: Location): SupportedLocales => {
	if (location.hostname.includes('sipgate.co.uk')) return 'en_GB';
	if (location.hostname.includes('clinq.com')) return 'en_US';
	return 'de_DE';
};

export const isUKPostcode = (postcode: string) =>
	/([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/.test(
		postcode
	);

const isTrunking = (hostname: string) => hostname.includes('trunking');

export const getIdentifier = (location: Location) => {
	const result = location.pathname.match(
		'[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}'
	);
	if (result && result.length > 0) {
		return result[0];
	}
};
export const getProduct = (location: Location) => {
	if (isTrunking(location.hostname)) {
		return 'trunking';
	}

	if (location.pathname.includes('affiliate') || location.pathname.includes('partnerprogramm')) {
		return 'affiliate';
	}

	return 'team';
};

export const getTacIds = (product: SipgateProduct, domain: SipgateDomain) => {
	if (product === 'trunking' && domain === 'sipgate.de') {
		return [105, 108, 96];
	}

	if (product === 'trunking' && domain === 'sipgate.co.uk') {
		return [83, 98, 127];
	}

	if (product === 'team' && domain === 'sipgate.co.uk') {
		return [692, 98, 127];
	}

	if (product === 'affiliate' && domain === 'sipgate.de') {
		return [817, 782, 380];
	}

	if (product === 'affiliate' && domain === 'sipgate.co.uk') {
		return [692, 98, 127, 496];
	}

	if (product === 'team' && domain === 'clinq.com') {
		return [878, 879, 889];
	}

	// sipgate.de sipgate
	return [817, 782];
};

type Env = 'live' | 'dev' | 'local';

export const getEnv = (location: Location): Env => {
	if (location.hostname.match('[clinq]?.*dev.*[clinq]?')) {
		return 'dev';
	}
	if (location.hostname.match('[clinq]?.*local.*[clinq]?')) {
		return 'local';
	}
	return 'live';
};

export const isTestEnv = () => process.env.NODE_ENV === 'test';
