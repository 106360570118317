import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslations } from './hooks/translations';
import { PusherContext } from './components/PusherContext';
import { pusher } from './api';
import { NqModal } from './components/nqNupsi/NQNupsi';
import classes from './SignupApp.scss';
import Routes from './Routes';

const SignupApp = () => {
	const translations = useTranslations();

	if (translations.fetched) {
		return (
			<div
				className={classnames(classes.cover, {
					[classes.withBackground]: window.top === window.self,
				})}
			>
				<PusherContext.Provider value={{ client: pusher }}>
					<Router>
						<Routes />
					</Router>
					<NqModal />
				</PusherContext.Provider>
			</div>
		);
	}
	return null;
};

export default SignupApp;
