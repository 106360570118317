import React from 'react';
import { useHistory } from 'react-router';
import { Headline } from '@panda/ui';

import classes from './Waitlist.scss';
import Layout from '../signup/Layout';
import { useTranslations } from '../../hooks/translations';
import useSignupData from '../../hooks/SignupData';
import { MarkdownTranslate } from '../../utils/markdown';
import NQActivate from '../../components/nqNupsi/NQActivate';

const Waitlist = () => {
	const { translate } = useTranslations();
	const history = useHistory();

	const { signupData } = useSignupData();
	const { email, activationCode } = signupData;

	if (!email) {
		history.replace('/');
		return <></>;
	}

	return (
		<Layout>
			<div className={classes.success}>
				<div className={classes.content}>
					<Headline>
						<MarkdownTranslate
							translate={translate}
							translationKey="SIGNUP_WAITLIST_HEADLINE"
							placeholder={[]}
						/>
					</Headline>
					<span className={classes.message}>
						<MarkdownTranslate
							translate={translate}
							translationKey="SIGNUP_WAITLIST_MESSAGE"
							placeholder={[email]}
						/>
					</span>
					{activationCode && <NQActivate />}
				</div>
			</div>
		</Layout>
	);
};

export { Waitlist };
