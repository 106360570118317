import {
	activateSignup,
	createPartialSignup,
	createSignup,
	getSignup,
	SignupErrors,
	signupSlice,
} from '../redux/slices/signup';
import { useAppDispatch, useAppSelector } from './redux';
import {
	getLandingPageUrl,
	getSignupData,
	getSignupError,
	hasSignupError,
	isFetched,
	isFetching,
} from '../redux/slices/signup/selectors';
import { PartialSignupData, SignupCreatedResponse } from '../types';

interface SignupDataHook {
	signupData: ReturnType<typeof getSignupData>;
	landingPageUrl: string;

	clearAddressData: (country: string) => void;
	createSignup: (data: PartialSignupData) => Promise<SignupCreatedResponse>;
	createPartialSignup: (data: { email: string }) => Promise<SignupCreatedResponse>;
	activateSignup: (key: string) => void;

	fetched: boolean;
	fetching: boolean;
	fetchSignupData: (identifier: string) => void;
	hasError: boolean;
	error?: SignupErrors;
}

export const useSignupData = (identifier?: string): SignupDataHook => {
	const dispatch = useAppDispatch();
	const fetched = useAppSelector(isFetched);
	const fetching = useAppSelector(isFetching);
	const hasError = useAppSelector(hasSignupError);
	const error = useAppSelector(getSignupError);
	const signupData = useAppSelector(getSignupData);

	if (!fetched && !fetching && !hasError && identifier) {
		dispatch(getSignup(identifier));
	}

	const clearAddressData = (country: string) =>
		dispatch(signupSlice.actions.clearAddressData(country));

	return {
		signupData,
		landingPageUrl: useAppSelector(getLandingPageUrl),
		fetched,
		fetching,
		hasError,
		fetchSignupData: (id: string) => dispatch(getSignup(id)),
		clearAddressData,
		createSignup: async (data: PartialSignupData) => dispatch(createSignup(data)).unwrap(),
		createPartialSignup: async (data: { email: string }) =>
			dispatch(createPartialSignup(data)).unwrap(),
		activateSignup: async (key: string) => dispatch(activateSignup(key)),
		error,
	};
};

export default useSignupData;
