import classnames from 'classnames';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ExternalLink } from '@panda/ui';
import api from '../../../api';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import useTranslations from '../../../hooks/translations';
import { setTacs } from '../../../redux/slices/tacs';
import { getTacState } from '../../../redux/slices/tacs/selectors';
import { MarkdownTranslate } from '../../../utils/markdown';
import classes from './TacGroup.scss';
import useSignupData from '../../../hooks/SignupData';

const TacGroup = () => {
	const { translate } = useTranslations();
	const { signupData } = useSignupData();
	const { domain, product } = signupData;

	const dispatch = useAppDispatch();
	const tacState = useAppSelector(getTacState);

	const location = useLocation();
	const hT = new URLSearchParams(location.search).get('hT');

	useEffect(() => {
		api.getTacIDs(domain, product).then(result => {
			if (result.isOk()) {
				dispatch(setTacs(result.value));
			}
		});
	}, [domain, product, dispatch]);

	const tacString =
		tacState.items.length > 1
			? tacState.items
					.map<React.ReactNode>(tac => (
						<React.Fragment key={tac.signedTacIdentifier}>
							<span>{translate(`TAC_TITLE_${tac.type}_ARTICLE`)}&nbsp;</span>
							<ExternalLink
								to={`/tac/${encodeURI(tac.signedTacIdentifier)}`}
								variant="quiet"
								target="blank"
								key={tac.signedTacIdentifier}
							>
								{translate(`TAC_TITLE_${tac.type}`)}
							</ExternalLink>
						</React.Fragment>
					))
					.reduce((prev, curr, currentIndex) => [
						prev,
						currentIndex === tacState.items.length - 1
							? ` ${translate('SIGNUP_TACS_GENERIC_AND')} `
							: ', ',
						curr,
					])
			: null;

	if (hT) {
		return null;
	}

	switch (domain) {
		case 'sipgate.co.uk':
			return (
				<div className={classes.group}>
					<div className={classnames(classes.row, classes.width12, classes.hint, classes.block)}>
						<MarkdownTranslate
							variant="quiet"
							translate={translate}
							translationKey={
								product === 'affiliate' ? 'SIGNUP_TACS_UK_AFFILIATE' : 'SIGNUP_TACS_UK'
							}
							placeholder={[]}
						/>
					</div>
				</div>
			);
		case 'clinq.com':
			return (
				<div className={classes.group}>
					<div className={classnames(classes.row, classes.width12, classes.hint, classes.block)}>
						<span>{translate('SIGNUP_TACS_GENERIC').split('$TACS$')[0]}&nbsp;</span>
						{tacString}&nbsp;
						<span>{translate('SIGNUP_TACS_GENERIC').split('$TACS$')[1]}</span>
					</div>
				</div>
			);
		default:
			return (
				<div className={classes.group}>
					<div className={classnames(classes.row, classes.width12, classes.hint, classes.block)}>
						<span>{translate('SIGNUP_TACS_GENERIC').split('$TACS$')[0]}&nbsp;</span>
						{tacString}&nbsp;
						<span>{translate('SIGNUP_TACS_GENERIC').split('$TACS$')[1]}</span>
					</div>
				</div>
			);
	}
};

export default TacGroup;
