import React, { useEffect } from 'react';
import { ExternalLink, HeadingBoundary, Headline } from '@panda/ui';
import classes from './ErrorPage.scss';
import ErrorIllustration from './Error.svg';
import useTranslations from '../../hooks/translations';
import { useLoadingAnimation } from '../../hooks/LoadingAnimation';
import { MarkdownTranslate } from '../../utils/markdown';
import { useAppSelector } from '../../hooks/redux';
import { getDomain } from '../../redux/slices/signup/selectors';

const ErrorPage = () => {
	const { translate, fetched, fetchTranslations } = useTranslations();
	const domain = useAppSelector(getDomain);
	const loadingAnimation = useLoadingAnimation();

	useEffect(() => {}, [fetched]);

	useEffect(() => {
		fetchTranslations();
	}, [fetchTranslations]);

	useEffect(() => {
		if (fetched) {
			loadingAnimation.hide();
		}
	}, [fetched, loadingAnimation]);

	return (
		<div className={classes.wrapper}>
			<div>
				<img src={ErrorIllustration} alt={translate('SIGNUP_GENERIC_ERROR_HEADLINE')} />
			</div>
			<div className={classes.content}>
				<HeadingBoundary>
					<Headline>
						<MarkdownTranslate
							translate={translate}
							translationKey="SIGNUP_GENERIC_ERROR_HEADLINE"
							placeholder={[]}
						/>
					</Headline>
				</HeadingBoundary>
				<span className={classes.message}>
					<MarkdownTranslate
						translate={translate}
						translationKey="SIGNUP_GENERIC_ERROR_MESSAGE"
						placeholder={[]}
					/>
				</span>
				{domain !== 'clinq.com' && (
					<span className={classes.button}>
						<ExternalLink
							button
							to="https://status.sipgate.de/"
							variant="loud"
							size="medium"
							width="content"
						>
							{translate('SIGNUP_GENERIC_ERROR_BUTTON')}
						</ExternalLink>
					</span>
				)}
			</div>
		</div>
	);
};

export default ErrorPage;
