import React from 'react';
import ClinqLogo from '../../../media/images/clinq_logo.svg';
import classes from './EntryPage.scss';

interface Props {
	href: string;
}

const BrandLogo = ({ href }: Props) => {
	return (
		<a href={href} className={window.top !== window.self ? classes.hide : classes.sipgateLogo}>
			<img src={ClinqLogo} alt="clinq logo" aria-hidden="true" />
		</a>
	);
};

export default BrandLogo;
