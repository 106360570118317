import sha256 from 'sha256';
import { Analytics } from '@segment/analytics-node';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SipgateDomain, SipgateProduct } from '../redux/slices/signup';
import { getEnv, isTestEnv } from './index';
import { InternationalSignupData } from '../types';
import { getCookie, setCookie } from './cookies';

export const pushEvent = (
	status: 'success' | 'failure',
	formId: string,
	product: SipgateProduct,
	errors?: Record<string, string>
) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'formSubmit',
			sipgateForm: {
				product,
				status,
				id: formId,
				errors,
			},
		});
	}
};

const getApiKey = () => {
	const env = getEnv(window.location);
	if (env === 'live') {
		return 'Jb3EW2E5JXwK1q7FICwK0XLKZPTGhJOh';
	}
	return 'sPC6OUyEKOQuAbz2QlcfigB2zjsMBgX9';
};

const analytics = new Analytics({
	writeKey: getApiKey(),
	host: 'https://events.eu1.segmentapis.com',
	disable: isTestEnv(),
	flushAt: 1,
});

const getAnonymousId = () => {
	return getCookie('ajs_anonymous_id');
};

const setAnonymousId = () => {
	setCookie('ajs_anonymous_id', uuidv4(), 1);
};

export const trackEmailSignUp = (email: string) => {
	analytics.identify({
		userId: sha256(email),
		traits: {
			email,
		},
		context: {
			messaging_subscriptions: [
				{
					key: email,
					type: 'EMAIL',
					status: 'SUBSCRIBED',
					groups: [{ name: 'CLINQ US', status: 'SUBSCRIBED' }],
				},
			],
		},
	});

	const anonymousId = getAnonymousId();
	if (anonymousId) {
		analytics.alias({
			userId: sha256(email),
			previousId: anonymousId,
		});
	}

	analytics.track({
		userId: sha256(email),
		event: 'button_click',
		properties: {
			button_name: 'email_signup',
			button_text: 'Continue with email',
		},
	});
};

export const trackSignUp = (
	data: Omit<InternationalSignupData, 'domain' | 'product' | 'identityProvider'>,
	signupId: string,
	country: Omit<string, 'DE'>,
	domain: SipgateDomain
) => {
	const userId = sha256(data.email);

	analytics.identify({
		userId,
		traits: {
			firstName: data.firstname,
			lastName: data.lastname,
			email: data.email,
			phone: data.phoneNumber,
			address: {
				city: data.city,
				country: data.country as string,
				postalCode: data.zip,
			},
			signup_id: signupId,
			country,
		},
	});

	analytics.track({
		userId,
		event: 'sign_up',
		properties: {
			signup_id: signupId,
			country,
			source: 'web',
			product_group: domain,
		},
	});
};

const trackPage = (location: Location, document: Document) => {
	const anonymousId = getAnonymousId();
	if (anonymousId) {
		analytics.page({
			anonymousId,
			name: document.title,
			properties: {
				url: location.toString(),
			},
		});
	}
};

export const usePageTracking = () => {
	const location = useLocation();

	const anonymousId = getAnonymousId();
	if (!anonymousId) {
		setAnonymousId();
	}

	useEffect(() => {
		trackPage(window.location, window.document);
	}, [location]);
};
