import React from 'react';
import { ManagedSelect, Option } from '@panda/ui';
import { ManagedForm } from '@web-apps/forms';
import { useFormikContext } from 'formik';
import useTranslations from '../../../hooks/translations';

const options = {
	BE: 'SIGNUP_ADDRESS_COUNTRY_BE',
	BG: 'SIGNUP_ADDRESS_COUNTRY_BG',
	DE: 'SIGNUP_ADDRESS_COUNTRY_DE',
	GB: 'SIGNUP_ADDRESS_COUNTRY_UK',
	DK: 'SIGNUP_ADDRESS_COUNTRY_DK',
	EE: 'SIGNUP_ADDRESS_COUNTRY_EE',
	FI: 'SIGNUP_ADDRESS_COUNTRY_FI',
	FR: 'SIGNUP_ADDRESS_COUNTRY_FR',
	GR: 'SIGNUP_ADDRESS_COUNTRY_GR',
	IE: 'SIGNUP_ADDRESS_COUNTRY_IE',
	IS: 'SIGNUP_ADDRESS_COUNTRY_IS',
	IT: 'SIGNUP_ADDRESS_COUNTRY_IT',
	HR: 'SIGNUP_ADDRESS_COUNTRY_HR',
	LI: 'SIGNUP_ADDRESS_COUNTRY_LI',
	LT: 'SIGNUP_ADDRESS_COUNTRY_LT',
	LU: 'SIGNUP_ADDRESS_COUNTRY_LU',
	LV: 'SIGNUP_ADDRESS_COUNTRY_LV',
	MT: 'SIGNUP_ADDRESS_COUNTRY_MT',
	NL: 'SIGNUP_ADDRESS_COUNTRY_NL',
	NO: 'SIGNUP_ADDRESS_COUNTRY_NO',
	AT: 'SIGNUP_ADDRESS_COUNTRY_AT',
	PL: 'SIGNUP_ADDRESS_COUNTRY_PL',
	PT: 'SIGNUP_ADDRESS_COUNTRY_PT',
	RO: 'SIGNUP_ADDRESS_COUNTRY_RO',
	SE: 'SIGNUP_ADDRESS_COUNTRY_SE',
	SI: 'SIGNUP_ADDRESS_COUNTRY_SI',
	SK: 'SIGNUP_ADDRESS_COUNTRY_SK',
	ES: 'SIGNUP_ADDRESS_COUNTRY_ES',
	CZ: 'SIGNUP_ADDRESS_COUNTRY_CZ',
	HU: 'SIGNUP_ADDRESS_COUNTRY_HU',
	CY: 'SIGNUP_ADDRESS_COUNTRY_CY',
	US: 'SIGNUP_ADDRESS_COUNTRY_US',
} as { [key: string]: string };

interface Props {
	filter: (value: string) => boolean;
}

const CountryField = ({ filter }: Props) => {
	const { translate } = useTranslations();
	const formik = useFormikContext<{ country: string }>();
	const data = Object.keys(options).filter(filter);
	const sorted = data.sort((a, b) => translate(options[a]).localeCompare(translate(options[b])));

	const setValue = (value: string) => {
		formik.setFieldTouched('country');
		ManagedForm.buildField(formik, 'country').setValue(value);
	};

	const fieldProps = {
		...ManagedForm.buildField(formik, 'country'),
		setValue,
	};

	return (
		<ManagedSelect
			managedField={fieldProps}
			title={translate('SIGNUP_ADDRESS_COUNTRY_LABEL')}
			disabled={sorted.length === 1}
		>
			{sorted.map(key => (
				<Option value={key} key={key.toLowerCase()}>
					{translate(options[key])}
				</Option>
			))}
		</ManagedSelect>
	);
};

export { CountryField };
