import { Headline } from '@panda/ui';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { usePusherContext } from '../../components/PusherContext';
import classes from './Status.scss';
import { useTranslations } from '../../hooks/translations';
import { useLoadingAnimation } from '../../hooks/LoadingAnimation';
import { MarkdownTranslate } from '../../utils/markdown';
import useSignupData from '../../hooks/SignupData';

export default function Status() {
	const { translate } = useTranslations();
	const { identifier } = useParams<{ identifier: string }>();

	const pusher = usePusherContext();
	const loadingAnimation = useLoadingAnimation();
	loadingAnimation.hide();

	const history = useHistory();

	const { signupData } = useSignupData();
	const { email } = signupData;

	useEffect(() => {
		if (identifier) {
			pusher.client.then(client => {
				return client.listen<{ url: string }>(
					identifier,
					'login_possible',
					(event: { url: string }) => {
						window.location.href = event.url;
					}
				);
			});
		}
	}, [identifier, pusher.client]);

	if (!email) {
		history.replace('/');
		return <></>;
	}

	return (
		<div className={classes.activate}>
			<div className={classes.content}>
				<Headline>
					<MarkdownTranslate
						translate={translate}
						translationKey="SIGNUP_ACTIVATED_HEADLINE"
						placeholder={[]}
					/>
				</Headline>
				<span className={classes.message}>
					<MarkdownTranslate
						translate={translate}
						translationKey="SIGNUP_ACTIVATED_MESSAGE"
						placeholder={[email ?? 'default']}
					/>
				</span>
			</div>
		</div>
	);
}
