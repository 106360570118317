import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EntryPage from './pages/signup/EntryPage/EntryPage';
import Activate from './pages/Activate/Activate';
import Status from './pages/status/Status';
import ErrorBoundary from './utils/ErrorBoundary';
import Tac from './pages/tac/Tac';
import { Signup } from './pages/signup/Signup';
import ErrorPage from './pages/error/ErrorPage';
import { Waitlist } from './pages/Waitlist/Waitlist';
import { usePageTracking } from './utils/tracking';

const UUID_PATTERN = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

const Routes = () => {
	usePageTracking();

	return (
		<Switch>
			<ErrorBoundary>
				<Route path="/" exact component={EntryPage} />
				<Route path={`/:identifier(${UUID_PATTERN})`} exact component={Signup} />
				<Route path="/waitlist/:identifier" exact component={Waitlist} />
				<Route path="/activate/:key" exact component={Activate} />
				<Route path="/status/:identifier" exact component={Status} />
				<Route path="/tac/:tacIdentifier" exact component={Tac} />
				<Route path="/error" exact component={ErrorPage} />
			</ErrorBoundary>
		</Switch>
	);
};

export default Routes;
