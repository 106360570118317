import React from 'react';
import classes from './Layout.scss';
import ClinqLogo from '../../media/images/clinq_logo.svg';

type Props = {
	children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
	return (
		<div className={classes.wrapper}>
			<img className={classes.sipgateLogo} src={ClinqLogo} alt="clinq logo" aria-hidden="true" />
			<main className={classes.content}>{children}</main>
		</div>
	);
};

export default Layout;
